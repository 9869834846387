import { Notify, Block, Confirm, Loading } from "notiflix";

Notify.init({
    width: "280px",
    position: "center-bottom",
    distance: "10px",
    opacity: 1,
    borderRadius: "5px",
    rtl: false,
    timeout: 4000,
    messageMaxLength: 110,
    backOverlay: false,
    backOverlayColor: "rgba(0,0,0,0.5)",
    plainText: true,
    showOnlyTheLastOne: false,
    clickToClose: false,
    pauseOnHover: false,
    ID: "NotiflixNotify",
    className: "notiflix-notify",
    zindex: 4001,
    fontFamily: "Nunito",
    fontSize: "13px",
    cssAnimation: true,
    cssAnimationDuration: 400,
    cssAnimationStyle: "from-bottom",
    closeButton: false,
    useIcon: true,
    useFontAwesome: false,
    fontAwesomeIconStyle: "basic",
    fontAwesomeIconSize: "34px",
    success: {
        background: "#32c682",
        textColor: "#fff",
        childClassName: "notiflix-notify-success",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-check-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(50,198,130,0.2)",
    },
    failure: {
        background: "#ff5549",
        textColor: "#fff",
        childClassName: "notiflix-notify-failure",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-times-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(255,85,73,0.2)",
    },
    warning: {
        background: "#eebf31",
        textColor: "#fff",
        childClassName: "notiflix-notify-warning",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-exclamation-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(238,191,49,0.2)",
    },
    info: {
        background: "#26c0d3",
        textColor: "#fff",
        childClassName: "notiflix-notify-info",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-info-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(38,192,211,0.2)",
    },
});

Confirm.init({
    className: "notiflix-confirm",
    width: "320px",
    zindex: 4003,
    position: "center",
    distance: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    backOverlay: true,
    backOverlayColor: "rgba(0,0,0,0.3)",
    rtl: false,
    fontFamily: "Nunito",
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: "fade",
    plainText: false,
    titleColor: "#0081C9",
    titleFontSize: "16px",
    titleMaxLength: 3440,
    messageColor: "#1e1e1e",
    messageFontSize: "14px",
    messageMaxLength: 11000,
    buttonsFontSize: "15px",
    buttonsMaxLength: 34,
    okButtonColor: "#f8f8f8",
    okButtonBackground: "#0081C9",
    cancelButtonColor: "#f8f8f8",
    cancelButtonBackground: "#a9a9a9",
});

Block.init({
    querySelectorLimit: 200,
    className: "notiflix-block",
    position: "absolute",
    zindex: 1000,
    backgroundColor: "rgba(8,8,8,0.9)",
    rtl: false,
    fontFamily: "Nunito",
    cssAnimation: true,
    cssAnimationDuration: 300,
    svgSize: "45px",
    svgColor: "#000000",
    messageFontSize: "14px",
    messageMaxLength: 34,
    messageColor: "#000000",
});

Notify.init({
    width: "280px",
    position: "center-bottom",
    distance: "10px",
    opacity: 1,
    borderRadius: "5px",
    rtl: false,
    timeout: 4000,
    messageMaxLength: 110,
    backOverlay: false,
    backOverlayColor: "rgba(0,0,0,0.5)",
    plainText: true,
    showOnlyTheLastOne: false,
    clickToClose: false,
    pauseOnHover: false,
    ID: "NotiflixNotify",
    className: "notiflix-notify",
    zindex: 4001,
    fontFamily: "Nunito",
    fontSize: "13px",
    cssAnimation: true,
    cssAnimationDuration: 400,
    cssAnimationStyle: "from-bottom",
    closeButton: false,
    useIcon: true,
    useFontAwesome: false,
    fontAwesomeIconStyle: "basic",
    fontAwesomeIconSize: "34px",
    success: {
        background: "#32c682",
        textColor: "#fff",
        childClassName: "notiflix-notify-success",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-check-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(50,198,130,0.2)",
    },
    failure: {
        background: "#ff5549",
        textColor: "#fff",
        childClassName: "notiflix-notify-failure",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-times-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(255,85,73,0.2)",
    },
    warning: {
        background: "#eebf31",
        textColor: "#fff",
        childClassName: "notiflix-notify-warning",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-exclamation-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(238,191,49,0.2)",
    },
    info: {
        background: "#26c0d3",
        textColor: "#fff",
        childClassName: "notiflix-notify-info",
        notiflixIconColor: "#fff",
        fontAwesomeClassName: "fas fa-info-circle",
        fontAwesomeIconColor: "rgba(0,0,0,0.2)",
        backOverlayColor: "rgba(38,192,211,0.2)",
    },
});

Confirm.init({
    className: "notiflix-confirm",
    width: "320px",
    zindex: 4003,
    position: "center",
    distance: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "5px",
    backOverlay: true,
    backOverlayColor: "rgba(0,0,0,0.3)",
    rtl: false,
    fontFamily: "Nunito",
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: "fade",
    plainText: false,
    titleColor: "#0081C9",
    titleFontSize: "16px",
    titleMaxLength: 3440,
    messageColor: "#1e1e1e",
    messageFontSize: "14px",
    messageMaxLength: 11000,
    buttonsFontSize: "15px",
    buttonsMaxLength: 34,
    okButtonColor: "#f8f8f8",
    okButtonBackground: "#0081C9",
    cancelButtonColor: "#f8f8f8",
    cancelButtonBackground: "#a9a9a9",
});

Block.init({
    querySelectorLimit: 200,
    className: "notiflix-block",
    position: "absolute",
    zindex: 1000,
    backgroundColor: "rgba(255,255,255,0.9)",
    rtl: false,
    fontFamily: "Nunito",
    cssAnimation: true,
    cssAnimationDuration: 300,
    svgSize: "45px",
    svgColor: "#ff5a1f",
    messageFontSize: "14px",
    messageMaxLength: 34,
    messageColor: "#ff5a1f",
});

Loading.init({
    querySelectorLimit: 200,
    className: "notiflix-block",
    position: "absolute",
    zindex: 1000,
    backgroundColor: "rgba(255,255,255,0.9)",
    rtl: false,
    fontFamily: "Nunito",
    cssAnimation: true,
    cssAnimationDuration: 300,
    svgSize: "45px",
    svgColor: "#ff5a1f",
    messageFontSize: "14px",
    messageMaxLength: 34,
    messageColor: "#ff5a1f",
});
