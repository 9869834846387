import "./bootstrap";
import "tailwindcss/tailwind.css";
import "./commons/configs";
import { createApp, defineAsyncComponent } from "vue";
import "flowbite";
import PrimeVue from "primevue/config";

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import "datatables.net-dt";
import DataTableSelect from "datatables.net-select";
import ButtonsHtml5 from "datatables.net-buttons/js/buttons.html5";
import Buttons from "datatables.net-buttons/js/dataTables.buttons";
import jszip from "jszip";
import "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// eslint-disable-next-line no-undef
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-buttons-dt";
import "datatables.net-buttons/js/buttons.html5.mjs";
import Select2 from "vue3-select2-component";

DataTable.use(DataTablesCore);
DataTablesCore.Buttons.jszip(jszip);
DataTable.use(DataTableSelect);
DataTable.use(Buttons);
DataTable.use(ButtonsHtml5);
// DataTable.use(pdfmake)

const HomeComponent = defineAsyncComponent(() => import("./components/HomeComponent"));
const Producto = defineAsyncComponent(() => import("./pages/Producto"));
const LoginPage = defineAsyncComponent(() => import("./pages/LoginPage"));
const DashboardPage = defineAsyncComponent(() => import("./pages/DashboardPage"));
const PlanesDePagoPage = defineAsyncComponent(() => import("./pages/PlanesDePagoPage"));
const MediosDePagoPage = defineAsyncComponent(() => import("./pages/MediosDePagoPage"));
const DashboardLayout = defineAsyncComponent(() => import("./components/Dashboard.layout"));
const PrecieroPage = defineAsyncComponent(() => import("./pages/PrecieroPage"));
const PlanesPage = defineAsyncComponent(() => import("./pages/PlanesPage"));
const PlanesById = defineAsyncComponent(() => import("./pages/PlanesById"));
const HomePage = defineAsyncComponent(() => import("./pages/HomePage"));

const components = [
    { name: "home-component", component: HomeComponent },
    { name: "product-page", component: Producto },
    { name: "planes-page", component: PlanesPage },
    { name: "planes-by-id", component: PlanesById },
    { name: "dashboard-page", component: DashboardPage },
    { name: "dashboard-layout", component: DashboardLayout },
    { name: "login-page", component: LoginPage },
    { name: "planes-si-page", component: PlanesDePagoPage },
    { name: "medios-de-pago-page", component: MediosDePagoPage },
    { name: "preciero-page", component: PrecieroPage },
    { name: "home-page", component: HomePage },
];

const app = createApp({});
app.use(PrimeVue);
app.component("DataTable", DataTable);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Select2", Select2);
components.forEach(({ name, component }) => app.component(name, component));
app.mount("#app");
